<template>
<div class="AddCreditCard page">
  <headers title="设置银行卡"></headers>
  <div class="scroll">
    <ul class="list">
      <li class="flexCenter" v-for="(item,index) in form_list" :key="index">
        <div class="label">{{item.label}}</div>
        <van-field
            v-model="item.value"
            label=""
            :placeholder="item.placeholder"
            :type="item.type"
        />
        <van-icon name="arrow" class="arrowRight" v-if="index > 0" size="20" color="rgb(197, 197, 197)"/>
      </li>
    </ul>
    <van-button style="margin-top: 25px;" round block type="info" native-type="submit" color="linear-gradient(180deg,#9eb0ff,#4969ff)" @click="submit()">修改</van-button>

  </div>
</div>
</template>

<script>
import headers from "@/components/headers.vue";

export default {
  name: "AddCreditCard",
  components: {headers},
  data() {
    return {
      info: {},
      form_list: [
        {
          label: '姓名',
          placeholder: '请输入持卡人',
          type: 'text',
          key: 'name',
          value: ''
        },
        {
          label: '所属银行',
          type: 'text',
          key: 'bankname',
          placeholder: '请输入您的所属银行',
          value: ''
        },
        {
          label: '银行卡号',
          type: 'number',
          key: 'banknum',
          placeholder: '请输入银行卡号',
          value: ''
        },
        {
          label: '开户行地址',
          type: 'text',
          key: 'bank_address',
          placeholder: '请确认开户行地址',
          value: ''
        },
        {
          label: '手机号',
          type: 'text',
          key: 'phone',
          placeholder: '请输入手机号',
          value: ''
        }
      ],

    }
  },
  mounted() {
    this.getCardInfo()
  },
  methods: {
    getCardInfo(){
      $api.bankInfo({
        token: this.$store.getters['getToken']
      }).then(res => {
        this.info = res.data;
        this.form_list.forEach(item => {
          item.value = this.info[item.key].value;
        })
      })
    },
    submit(){
      let form = {};
      for (var i = 0; i < this.form_list.length; i++) {
        if (this.form_list[i].value === '') {
          this.$toast(this.form_list[i].label+'不能为空');
          return false;
        }
        form[this.form_list[i].key] = this.form_list[i].value
      }
      $api.tjyhk({
        ...form,
        id: this.info.id,
        uid: this.info.uid,
        bank_password: this.info.bank_password,
        shenfencode: this.info.shenfencode,
        addtime: this.info.addtime,
        token: this.$store.getters['getToken'],
      }).then(res => {
        this.$toast.success('修改成功');
        this.$router.back()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.AddCreditCard {
  .scroll {
    padding: 0 16px 30px;
    .list {
      li {
        height: 60px;
        border-bottom: 1px solid #f0f0f0;
        .label {
          width: 115px;
          text-align: left;
          font-size: 15px;
        }
      }
    }
  }
}
</style>